// extracted by mini-css-extract-plugin
export var forgot_password = "forgot_password-module--forgot_password--kly6s";
export var forgot_password__from = "forgot_password-module--forgot_password__from--Ciyi8";
export var forgot_password__success = "forgot_password-module--forgot_password__success--NsieY";
export var form__description = "forgot_password-module--form__description--Oc3y4";
export var form__link = "forgot_password-module--form__link--rbBsz";
export var form__title = "forgot_password-module--form__title--dn0-Y";
export var success__description = "forgot_password-module--success__description--KIw2A";
export var success__link = "forgot_password-module--success__link--LKBg5";
export var success__more = "forgot_password-module--success__more--+8IQa";
export var success__title = "forgot_password-module--success__title--QAmDT";